import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTableDataSelectableEvent,
  DataTableSelectionMultipleChangeEvent,
} from 'primereact/datatable';
import { Dialog, DialogProps } from 'primereact/dialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportPcfsResponse, useExportPcfs, useListProducts } from '../../../hooks/api/products';
import useNotificationContext from '../../../hooks/notification/useNotificationContext';
import { PCFBiogenicEmissionsMode } from '../../../shared/enums/pcf';
import { PCFRequestStatus } from '../../../shared/enums/pcf-request';
import { ToastSeverity } from '../../../shared/enums/toast-severity';
import { IProduct } from '../../../shared/interfaces/IProduct';
import ProductIdColBody from '../../products/components/ProductIdColBody';
import ProductNameColBody from '../../products/components/ProductNameColBody';
import ProductPcfValueColBody from '../../products/components/ProductPcfValueColBody';
import ProductRegionColBody from '../../products/components/ProductRegionColBody';
import SuccessToastContent from './components/SuccessToastContent';
import styles from './ExportPcfsModal.module.css';

interface ExportPcfsModalProps extends DialogProps {
  biogenicEmissionsMode: PCFBiogenicEmissionsMode;
}

const ExportPcfsModal = (props: ExportPcfsModalProps): JSX.Element => {
  const { biogenicEmissionsMode, onHide, ...rest } = props;
  const { t } = useTranslation();
  const { notify, clear } = useNotificationContext();

  // RQ Query to get products
  const { isLoading: isProductsLoading, data: products } = useListProducts();

  // Filter products with status 'Complete'
  const filteredProducts = products?.filter((product) => {
    return product.status === PCFRequestStatus.Complete;
  });

  // Callback for when RQ Mutation for exporting PCFs is successful
  const onExportPcfsSuccess = (data: ExportPcfsResponse) => {
    setSelectedProductsToExportCsv([]);

    // If there are any successfull exports, show success toast with the button, otherwise do nothing
    if (data.successCount) {
      notify({
        severity: ToastSeverity.SUCCESS,
        content: <SuccessToastContent response={data} clear={clear} />,
      });
    } else {
      notify({
        severity: ToastSeverity.ERROR,
        summary: t('toastMessages.exportPcfs.states.failed.title'),
        detail: t('toastMessages.exportPcfs.states.failed.detail'),
        life: 5000,
      });
    }
  };

  const onExportPcfsError = () => {
    notify({
      severity: ToastSeverity.ERROR,
      summary: t('toastMessages.exportPcfs.states.failed.title'),
      detail: t('toastMessages.exportPcfs.states.failed.detail'),
      life: 5000,
    });
  };

  const onExportPcfsSettled = () => {
    setSelectedProductsToExportCsv([]);
  };

  // RQ Mutation to export PCFs
  const { mutate: exportPcfs } = useExportPcfs({
    onSuccess: onExportPcfsSuccess,
    onError: onExportPcfsError,
    onSettled: onExportPcfsSettled,
  });

  // For exporting selected products to csv
  const [selectedProductsToExportCsv, setSelectedProductsToExportCsv] = useState<IProduct[]>([]);

  const isRowSelectable = (event: DataTableDataSelectableEvent) => {
    return event.data ? (event.data as IProduct).status === PCFRequestStatus.Complete : false;
  };

  const onSelectionChange = (e: DataTableSelectionMultipleChangeEvent<IProduct[]>) => {
    setSelectedProductsToExportCsv(e.value as IProduct[]);
  };

  const onClickExportPcfs = () => {
    // Get the product IDs of the selected products
    const productIds = selectedProductsToExportCsv.map((product) => {
      return product.product_id;
    });

    // Show an info toast when the export process starts
    notify({
      severity: ToastSeverity.INFO,
      summary: t('toastMessages.exportPcfs.states.exporting.title'),
      detail: t('toastMessages.exportPcfs.states.exporting.detail'),
      life: 5000,
    });

    // Make api call to get a signed URL for the CSV file
    exportPcfs({ productIds });

    // Hide the dialog
    onHide();
  };

  const footerContent = (
    <div className='flex flex-row gap-2 justify-content-end w-full'>
      <Button label={t('exportPcfsDialog.buttons.cancel')} outlined onClick={onHide} />
      <Button
        label={t('exportPcfsDialog.buttons.export')}
        disabled={selectedProductsToExportCsv.length === 0}
        onClick={onClickExportPcfs}
      />
    </div>
  );
  return (
    <Dialog
      header={t('exportPcfsDialog.title')}
      draggable={false}
      blockScroll
      footer={footerContent}
      style={{ width: '1000px' }}
      onHide={onHide}
      {...rest}
    >
      <DataTable
        selectionMode='checkbox'
        selection={selectedProductsToExportCsv}
        onSelectionChange={onSelectionChange}
        isDataSelectable={isRowSelectable}
        disabled={isProductsLoading}
        value={filteredProducts}
        scrollable
        pt={{
          thead: {
            className: 'z-2 !important',
          },
          wrapper: {
            className: styles['table-wrapper'],
          },
        }}
        className={styles['products-table']}
      >
        <Column
          selectionMode='multiple'
          header={t('exportPcfsDialog.selectAll')}
          headerStyle={{ width: '3rem' }}
        />
        <Column field='product_name' frozen className='z-1' body={ProductNameColBody} />
        <Column field='product_cid' body={ProductIdColBody} />
        <Column field='region' body={ProductRegionColBody} />
        <Column
          body={(product) => {
            return (
              <ProductPcfValueColBody
                product={product}
                biogenicEmissionsMode={biogenicEmissionsMode}
              />
            );
          }}
        />
      </DataTable>
    </Dialog>
  );
};

export default ExportPcfsModal;
