import Header from './components/header/Header';

type AppLayoutProps = {
  children: React.ReactNode;
};

const AppLayout = ({ children }: AppLayoutProps) => {
  return (
    <div className='min-h-screen'>
      <Header />
      {children}
    </div>
  );
};

export default AppLayout;
