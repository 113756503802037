import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import EmptyProductsImg from '../../../assets/images/empty-products.svg';
import EmptyScreen from '../../../components/empty-screen/EmptyScreen';
import RequestStatus from '../../../components/request-status/RequestStatus';
import { Locale } from '../../../contexts/language/LanguageContext';
import { useGetPcfRequests } from '../../../hooks/api/request-pcf';
import { AppRoutes } from '../../../shared/enums/app-routes';
import { PCFRequestStatus } from '../../../shared/enums/pcf-request';
import { mapProductRegionToKey } from '../../../shared/enums/product-region';
import { RequestType } from '../../../shared/enums/request-type';
import { parseDateWithoutTime } from '../../../shared/helpers/parse-date';
import { requestRegionSortFunction } from '../../../shared/helpers/products-sort-region-function';
import { requestsProductIdSortFunction } from '../../../shared/helpers/requests-product-id-sort-function';
import { IRequest } from '../../../shared/interfaces/IRequest';
import styles from './PcfRequestsTable.module.css';
import PcfRequestsTableHeader from './PcfRequestsTableHeader';
import ProductId from './ProductId';
import ProductName from './ProductName';

const PcfRequestsTable = (): JSX.Element => {
  const {
    i18n: { language },
  } = useTranslation();

  const [search, setSearch] = useState<string>('');

  // Debounced search value
  const [debouncedSearch] = useDebounce(search, 500);

  const { t } = useTranslation();

  const { data, isLoading, isError, error } = useGetPcfRequests({
    request_type: 'pcf,external_pcf,additional_product', // eslint-disable-line camelcase
    search: debouncedSearch.trim(),
  });

  if (isError) {
    return (
      <div className='text-error'>
        {t('pcfRequest.failedToGetRequests')} {error.toString()}
      </div>
    );
  }

  if (!isLoading && data?.length === 0 && !debouncedSearch) {
    return (
      <EmptyScreen imageSrc={EmptyProductsImg}>
        <p>{t('pcfRequest.emptyScreen.title')}</p>
        <p>
          {t('pcfRequest.emptyScreen.label1')}{' '}
          <Link to={AppRoutes.Products} className='text-primary underline'>
            {t('pcfRequest.emptyScreen.label2')}
          </Link>{' '}
          {t('pcfRequest.emptyScreen.label3')}
        </p>
      </EmptyScreen>
    );
  }

  const dateRequestedTemplate = (rowData: IRequest): React.ReactNode => {
    return parseDateWithoutTime(rowData.date_created ?? '', language as Locale).toLocaleString();
  };

  const requestedByTemplate = (rowData: IRequest): React.ReactNode => {
    return `${rowData.user.first_name} ${rowData.user.last_name}`;
  };

  const statusTemplate = (rowData: IRequest): React.ReactNode => {
    return <RequestStatus status={rowData.status} />;
  };

  const productNameTemplate = (rowData: IRequest): React.ReactNode => {
    let productName;
    let requestedProductName;

    if (rowData.request_type === RequestType.AdditionalProduct) {
      if (rowData.status !== PCFRequestStatus.Complete) {
        // If it is additional product request and hasn't been answered yet, then show additional product id as main product id
        productName = rowData?.other_request_data?.product_name;
      } else {
        // If it is additional product request and is already answered, show product id as main product id and additional product id below it
        productName = rowData?.product?.product_name;
        requestedProductName = rowData?.other_request_data?.product_name;
      }
    } else {
      // If it is not additional product request, show product id as main product id
      productName = rowData?.product?.product_name;
    }

    return <ProductName productName={productName} requestedProductName={requestedProductName} />;
  };

  const productIdTemplate = (rowData: IRequest): React.ReactNode => {
    let productId;
    let requestedProductId;

    if (rowData.request_type === RequestType.AdditionalProduct) {
      if (rowData.status !== PCFRequestStatus.Complete) {
        // If it is additional product request and hasn't been answered yet, then show additional product id as main product id
        productId = rowData?.other_request_data?.product_id;
      } else {
        // If it is additional product request and is already answered, show product id as main product id and additional product id below it
        productId = rowData?.product?.product_id?.toString();
        requestedProductId = rowData?.other_request_data?.product_id;
      }
    } else {
      // If it is not additional product request, show product id as main product id
      productId = rowData?.product?.product_id?.toString();
    }

    return <ProductId productId={productId} requestedProductId={requestedProductId} />;
  };

  const regionTemplate = (request: IRequest) => {
    if (!request.region) return;

    return t(`productsPage.regionNames.${mapProductRegionToKey(request.region)}`);
  };

  return (
    <DataTable
      value={data}
      loading={isLoading}
      scrollable
      sortField='date_created'
      sortOrder={-1}
      header={<PcfRequestsTableHeader setSearch={setSearch} />}
      pt={{
        wrapper: {
          className: styles['table-wrapper'],
        },
      }}
      className={styles['pcf-requests-table']}
    >
      <Column
        field='product.product_name'
        sortable
        header={t('pcfRequest.productName')}
        frozen
        body={productNameTemplate}
        className={styles['product-colummn']}
      />
      <Column
        field='product.product_id'
        sortable
        sortFunction={requestsProductIdSortFunction}
        header={t('pcfRequest.productId')}
        body={productIdTemplate}
        className={styles['product-colummn']}
      />
      <Column
        field='region'
        sortable
        sortFunction={requestRegionSortFunction}
        header={t('pcfRequest.region')}
        style={{ minWidth: '10rem' }}
        body={regionTemplate}
      />
      <Column
        body={dateRequestedTemplate}
        sortable
        sortField='date_created'
        header={t('pcfRequest.dateRequested')}
      />
      <Column
        body={requestedByTemplate}
        sortable
        sortField='user.first_name'
        header={t('pcfRequest.requestedBy')}
      />
      <Column body={statusTemplate} sortable sortField='status' header={t('pcfRequest.status')} />
    </DataTable>
  );
};

export default PcfRequestsTable;
