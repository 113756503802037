import { ButtonProps } from 'primereact/button';
import { IconOptions } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import ProductsFilledIcon from '../../../../../assets/icons/ProductsFilled';
import ProductsOutlineIcon from '../../../../../assets/icons/ProductsOutline';
import RequestsFilledIcon from '../../../../../assets/icons/RequestsFilled';
import RequestsOutlineIcon from '../../../../../assets/icons/RequestsOutline';
import MobileHeaderLink from '../header-link/MobileHeaderLink';

const MobileMenuOverlay = () => {
  const renderProductFilled = (options: IconOptions<ButtonProps, unknown>) => {
    const newProps = { ...options, fillColor: '#b3cb2d' };

    return <ProductsFilledIcon {...newProps} />;
  };

  const renderRequestsFilled = (options: IconOptions<ButtonProps, unknown>) => {
    const newProps = { ...options, fillColor: '#b3cb2d' };

    return <RequestsFilledIcon {...newProps} />;
  };

  const { t } = useTranslation();

  return (
    <>
      <MobileHeaderLink
        href='/products'
        label={t('global.productMenu')}
        icon={ProductsOutlineIcon}
        activeIcon={renderProductFilled}
      />

      <MobileHeaderLink
        href='/requests'
        label={t('global.requestMenu')}
        icon={RequestsOutlineIcon}
        activeIcon={renderRequestsFilled}
      />
    </>
  );
};

export default MobileMenuOverlay;
