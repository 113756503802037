import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';

import { OtherRequestDataType } from '../../../shared/interfaces/IProduct';

type ProductNameProps = {
  productName?: string;
  ownProductName?: string;
  otherRequestData?: OtherRequestDataType;
  href?: string;
};

const ProductName = ({
  productName,
  ownProductName,
  otherRequestData,
  href,
}: ProductNameProps): JSX.Element | null => {
  if (!productName) {
    return null;
  }

  const secondaryProductName = ownProductName ?? otherRequestData?.product_name ?? null;

  const navigate = useNavigate();

  const onProductNameClick = () => {
    if (href) {
      navigate(href);
    }
  };

  return (
    <div className='flex flex-column relative'>
      <div
        onClick={onProductNameClick}
        className={classNames('font-normal', {
          'cursor-pointer': !!href,
          'hover:underline': !!href,
        })}
      >
        {productName}
      </div>

      {secondaryProductName && (
        <div className='absolute top-100 white-space-nowrap overflow-hidden text-overflow-ellipsis w-full font-normal font-italic text-gray-600 line-height-2 mt-1'>
          {secondaryProductName}
        </div>
      )}
    </div>
  );
};

export default ProductName;
