import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReadyState } from 'react-use-websocket';

import InfoIconPrimary from '../../assets/icons/InfoIconPrimary';
import EmptyProductsImg from '../../assets/images/empty-products.svg';
import CustomSpinner from '../../components/custom-spinner/CustomSpinner';
import DownloadJsonFileButton from '../../components/download-pcf/DownloadJsonFileButton';
import DownloadPdfFileButton from '../../components/download-pcf/DownloadPdfFileButton';
import EmptyScreen from '../../components/empty-screen/EmptyScreen';
import RequestStatus from '../../components/request-status/RequestStatus';
import WebsocketContext, { IWebsocketMessage } from '../../contexts/websocket/WebsocketContext';
import { ProductsParams, useListProducts } from '../../hooks/api/products';
import { useCreatePcfRequest } from '../../hooks/api/request-pcf';
import useNotificationContext from '../../hooks/notification/useNotificationContext';
import { PCFBiogenicEmissionsMode } from '../../shared/enums/pcf';
import { PCFRequestStatus } from '../../shared/enums/pcf-request';
import { ToastSeverity } from '../../shared/enums/toast-severity';
import { requestRegionSortFunction } from '../../shared/helpers/products-sort-region-function';
import { IProduct } from '../../shared/interfaces/IProduct';
import RequestAdditionalProductModal from '../modals/request-additional-product-modal/RequestAdditionalProductModal';
import RequestPcfModal from '../modals/request-pcf-modal/RequestPcfModal';
import PCFInfoDialog from './components/PCFInfoDialog';
import ProductIdColBody from './components/ProductIdColBody';
import ProductNameColBody from './components/ProductNameColBody';
import ProductPcfValueColBody from './components/ProductPcfValueColBody';
import ProductRegionColBody from './components/ProductRegionColBody';
import ProductsHeader from './components/ProductsHeader';
import { ProductFilters } from './interfaces/product-item';
import styles from './ProductsPage.module.css';
import { initialProductsListRequest } from './toast/request-toast';

const ProductsPage = () => {
  const [biogenicEmissionsMode, setBiogenicEmissionsMode] = useState<PCFBiogenicEmissionsMode>(
    PCFBiogenicEmissionsMode.Include,
  );
  const [productFilters, setProductFilters] = useState<ProductFilters>({
    statusFilter: [],
    regionsFilter: [],
    searchStr: '',
  });

  // For requesting pcf for products with region, to disable the Request Pcf button for the requested product
  // It is a map of product_id to boolean, where boolean is true if the product is requested
  // So that you can request pcf for multiple products at the same time
  const [selectedProductsToRequestPcf, setSelectedProductsToRequestPcf] = useState<
    Record<string, boolean>
  >({});

  // For requesting pcf for a product without region
  // It will trigger the modal to show
  const [selectedProductToRequestPcf, setSelectedProductToRequestPcf] = useState<IProduct | null>(
    null,
  );

  // For exporting selected products to csv

  const [isAdditionalProductModalVisible, setIsAdditionalProductModalVisible] = useState(false);
  const [showPCFInfoDialog, setShowPCFInfoDialog] = useState(false);

  const { notify } = useNotificationContext();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { lastJsonMessage, readyState } = useContext(WebsocketContext);

  const { isLoading: loading, mutate: createInitialProductsListRequest } = useCreatePcfRequest({
    onSuccess: () => {
      const notifyData = initialProductsListRequest(
        ToastSeverity.INFO,
        t('DetailsContent.requestSubmitted'),
        t('DetailsContent.yourRequestWillBeReviewedAsSoonAsPossible'),
        t('DetailsContent.checkBackPeriodicallyToSeeIfYourProductsAreAdded'),
      );
      notify(notifyData);
    },
    onError: () => {
      const notifyData = initialProductsListRequest(
        ToastSeverity.ERROR,
        t('DetailsContent.failedToSubmitRequest'),
        t('DetailsContent.somethingWentWrong'),
        t('DetailsContent.pleaseTryAgainLater'),
      );
      notify(notifyData);
    },
  });

  /* eslint-disable camelcase */
  const { mutate: createPcfRequest } = useCreatePcfRequest({
    onMutate: (variables) => {
      // Make the RequestPcf button disabled for the requested product
      setSelectedProductsToRequestPcf((prev) => {
        return { ...prev, [variables.product_id as number]: true };
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listProducts'] });
      queryClient.invalidateQueries({ queryKey: ['requests'] });
    },
    onError: () => {
      notify({
        severity: ToastSeverity.ERROR,
        summary: t('DetailsContent.pcfRequestFailed'),
        detail: t('DetailsContent.pleaseTryAgainLater'),
        life: 5000,
      });
    },
    onSettled: (data, error, variables) => {
      setSelectedProductsToRequestPcf((prev) => {
        // After half a second, set the product to false to enable the button again
        // Make the RequestPcf button enabled for the requested product at the end
        return { ...prev, [variables.product_id as number]: false };
      });
    },
  });
  /* eslint-enable camelcase */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const params = useMemo(() => {
    const paramsValue: ProductsParams = {};
    if (productFilters.statusFilter.length) {
      paramsValue['status'] = productFilters.statusFilter.join(',');
    }

    if (productFilters.regionsFilter.length) {
      paramsValue['region'] = productFilters.regionsFilter.join(',');
    }

    if (productFilters.searchStr) {
      paramsValue['search'] = productFilters.searchStr.trim();
    }

    return paramsValue;
  }, [productFilters]);

  // RQ Query to get products
  const { isLoading, isError, data: products, refetch: refetchProducts } = useListProducts(params);

  useEffect(() => {
    if (readyState === ReadyState.OPEN && lastJsonMessage) {
      let parsedMessage;
      try {
        parsedMessage = lastJsonMessage as IWebsocketMessage;
      } catch (error) {
        console.error('Failed to parse message data:', error);
        return;
      }

      if (parsedMessage.eventType === 'request_pcf') {
        refetchProducts();

        if (parsedMessage.success === true) {
          notify({
            severity: ToastSeverity.SUCCESS,
            summary: t('toastMessages.pcfRequest.successSummary', {
              productName: parsedMessage.productName,
            }),
            detail: t('toastMessages.pcfRequest.successDetail'),
            life: 5000,
          });
        } else if (parsedMessage.success === false) {
          notify({
            severity: ToastSeverity.ERROR,
            summary: t('toastMessages.pcfRequest.errorSummary', {
              productName: parsedMessage.productName,
            }),
            detail: t('toastMessages.pcfRequest.errorDetail'),
            life: 5000,
          });
        }
      }
    }
  }, [readyState, lastJsonMessage]);

  const hideRequestPcfModal = () => {
    setSelectedProductToRequestPcf(null);
  };

  const onfilterChange = (filters: ProductFilters) => {
    setProductFilters(filters);
  };

  const handleTicketSubmission = () => {
    createInitialProductsListRequest({
      // eslint-disable-next-line camelcase
      request_type: 'initial_product_list',
    });
  };

  const onRequestPCFButtonClick = useCallback((product: IProduct) => {
    // If product has no region, show the modal
    if (!product.region) {
      setSelectedProductToRequestPcf(product);
    } else {
      // If product already has a region, fetch PCF data without showing any modal and refresh products

      /* eslint-disable camelcase */
      createPcfRequest({
        product_id: product.product_id,
        region: product.region,
        request_type: 'pcf',
        file_type: ['pdf', 'json'],
      });
      /* eslint-enable camelcase */
    }
  }, []);

  if (isError) {
    return <p className='text-red-500'>{t('productsPage.failedToGetProducts')}</p>;
  }

  const renderProductStatusColBody = (product: IProduct) => {
    return <RequestStatus status={product.status} />;
  };

  const renderProductPCFsColBody = (product: IProduct) => {
    return (
      <div className='flex'>
        {product.request_id && product.status === PCFRequestStatus.Complete && (
          <>
            <DownloadPdfFileButton requestId={product.request_id} />
            <DownloadJsonFileButton
              productName={product.product_name}
              requestId={product.request_id}
            />
          </>
        )}
      </div>
    );
  };

  const renderRequestPCFColBody = (product: IProduct) => {
    // If the product is already requested, don't show the button
    if (product.request_id && !selectedProductsToRequestPcf[product.product_id]) return;

    return (
      <Button
        className='w-full'
        label={t('productsPage.requestPcf')}
        disabled={selectedProductsToRequestPcf[product.product_id]}
        loading={selectedProductsToRequestPcf[product.product_id]}
        onClick={() => {
          return onRequestPCFButtonClick(product);
        }}
      />
    );
  };

  const productType = 'BASF';

  return (
    <div className={classNames('px-3 py-5 sm:p-5 flex flex-column', styles['products-page'])}>
      <h1 className='text-2xl md:text-4xl'>
        <Trans i18nKey='productsPage.heading' values={{ productType }} />
      </h1>

      {products?.length === 0 && Object.keys(params).length === 0 ? (
        <EmptyScreen imageSrc={EmptyProductsImg}>
          <h4>{t('emptyProductsPage.heading')}</h4>
          <p>{t('emptyProductsPage.description1')}</p>
          <div className='flex flex-wrap align-items-center'>
            <p className='m-0 flex flex-wrap align-items-center'>
              {t('emptyProductsPage.description2')}
              <Button
                label={
                  loading
                    ? t('emptyProductsPage.submittingTicket')
                    : t('emptyProductsPage.submitTicket')
                }
                onClick={handleTicketSubmission}
                className={classNames('text-primary underline p-0 ml-1', styles['submit-ticket'])}
                disabled={loading}
                text
              />
            </p>
            {loading && <CustomSpinner strokeWidth={8} className='w-1rem ml-2' />}
          </div>
        </EmptyScreen>
      ) : (
        <div className='flex flex-column gap-4'>
          <DataTable
            loading={isLoading}
            value={products}
            scrollable
            header={
              <ProductsHeader
                filterChange={onfilterChange}
                biogenicEmissionsMode={biogenicEmissionsMode}
                setBiogenicEmissionsMode={setBiogenicEmissionsMode}
              />
            }
            pt={{
              wrapper: {
                className: styles['table-wrapper'],
              },
            }}
          >
            <Column
              field='product_name'
              sortable
              header={t('productsPage.productName')}
              frozen
              body={ProductNameColBody}
            />
            <Column
              field='product_cid'
              sortable
              header={t('productsPage.productId')}
              style={{ minWidth: '6rem' }}
              body={ProductIdColBody}
            />
            <Column
              field='region'
              sortable
              sortFunction={requestRegionSortFunction}
              header={t('productsPage.region')}
              style={{ minWidth: '10rem' }}
              body={ProductRegionColBody}
            />
            <Column
              field={
                biogenicEmissionsMode === PCFBiogenicEmissionsMode.Include
                  ? 'pcf_including'
                  : 'pcf_excluding'
              }
              sortable
              header={t('productsPage.pcfValue')}
              body={(product) => {
                return (
                  <ProductPcfValueColBody
                    product={product}
                    biogenicEmissionsMode={biogenicEmissionsMode}
                  />
                );
              }}
              style={{ minWidth: '8rem' }}
            />
            <Column
              header={
                <div className='flex gap-2'>
                  {t('productsPage.pcfs')}
                  <Button
                    className='p-0 flex justify-content-start info-icon cursor-pointer'
                    link
                    icon={InfoIconPrimary}
                    onClick={() => {
                      return setShowPCFInfoDialog(true);
                    }}
                  />
                </div>
              }
              body={renderProductPCFsColBody}
              style={{ minWidth: '6rem' }}
            />

            <Column
              header={t('productsPage.status')}
              style={{ minWidth: '6rem' }}
              body={renderProductStatusColBody}
            />
            <Column body={renderRequestPCFColBody} className='w-12rem' />
          </DataTable>
          <Button
            className='mr-auto mb-4 px-3'
            label={t('productsPage.requestAdditionalProduct')}
            onClick={() => {
              setIsAdditionalProductModalVisible(true);
            }}
          />
        </div>
      )}
      {selectedProductToRequestPcf && (
        <RequestPcfModal product={selectedProductToRequestPcf} onClose={hideRequestPcfModal} />
      )}

      <RequestAdditionalProductModal
        visible={isAdditionalProductModalVisible}
        setVisible={setIsAdditionalProductModalVisible}
      />
      <PCFInfoDialog
        visible={showPCFInfoDialog}
        onHide={() => {
          return setShowPCFInfoDialog(false);
        }}
      />
    </div>
  );
};

export default ProductsPage;
