import { Auth } from 'aws-amplify';
import { useMemo } from 'react';
import useWebsocket from 'react-use-websocket';

import { useAuth } from '../../hooks/auth/useAuth';
import WebsocketContext, { IWebsocketContext } from './WebsocketContext';

/**
 * Retrieves the WebSocket URL with the appended idToken, to be used by useWebsocket method.
 * @returns A promise that resolves to the WebSocket URL with the idToken.
 */
export const getWebsocketUrl = async (): Promise<string> => {
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();

  return process.env.REACT_APP_WEBSOCKET_URL + '?idToken=' + idToken;
};

type WebsocketProviderProps = {
  children: React.ReactNode;
};

const WebsocketProvider = ({ children }: WebsocketProviderProps): JSX.Element => {
  const { isAuthenticated } = useAuth();

  const { lastMessage, lastJsonMessage, readyState } = useWebsocket(
    getWebsocketUrl,
    {
      shouldReconnect: () => {
        return true;
      },
      share: true,
    },
    isAuthenticated,
  );

  const state = useMemo<IWebsocketContext>(() => {
    return { lastMessage, lastJsonMessage, readyState };
  }, [lastMessage, lastJsonMessage, readyState]);

  return <WebsocketContext.Provider value={state}>{children}</WebsocketContext.Provider>;
};

export default WebsocketProvider;
