import { OtherRequestDataType } from '../../../shared/interfaces/IProduct';

type ProductIdProps = {
  productId?: string;
  ownProductId?: string;
  otherRequestData?: OtherRequestDataType;
};

export default function ProductId({
  productId,
  ownProductId,
  otherRequestData,
}: ProductIdProps): JSX.Element | null {
  if (!productId) {
    return null;
  }

  const secondaryProductId = ownProductId ?? otherRequestData?.product_id ?? null;

  return (
    <div className='flex flex-column relative'>
      <div className='font-normal'>{productId}</div>
      {secondaryProductId && (
        <div className='absolute top-100 white-space-nowrap overflow-hidden text-overflow-ellipsis w-full font-normal font-italic text-gray-600 line-height-2 mt-1'>
          {secondaryProductId}
        </div>
      )}
    </div>
  );
}
