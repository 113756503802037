import { createContext } from 'react';
import { WebSocketHook } from 'react-use-websocket/dist/lib/types';

export type IWebsocketContext = Pick<
  WebSocketHook,
  'lastMessage' | 'lastJsonMessage' | 'readyState'
>;

export enum WebsocketEventType {
  NewNotification = 'new_notification',
  RequestPCF = 'request_pcf',
}

export interface IWebsocketMessage {
  user_id: number;
  eventType: WebsocketEventType;
  success?: boolean;
  message?: string;
  error?: string;
  productName?: string;
}

const WebsocketContext = createContext({} as IWebsocketContext);

export default WebsocketContext;
