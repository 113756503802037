import EmissionUnit from '../../../components/emission-unit/EmissionUnit';
import { PCFBiogenicEmissionsMode } from '../../../shared/enums/pcf';
import { IProduct } from '../../../shared/interfaces/IProduct';

type ProductPcfValueColBodyProps = {
  product: IProduct;
  biogenicEmissionsMode: PCFBiogenicEmissionsMode;
};

const ProductPcfValueColBody = ({
  product,
  biogenicEmissionsMode,
}: ProductPcfValueColBodyProps): JSX.Element | null => {
  if (biogenicEmissionsMode === PCFBiogenicEmissionsMode.Include && product.pcf_including) {
    return <EmissionUnit value={product.pcf_including} />;
  } else if (biogenicEmissionsMode === PCFBiogenicEmissionsMode.Exclude && product.pcf_excluding) {
    return <EmissionUnit value={product.pcf_excluding} />;
  }
  return null;
};

export default ProductPcfValueColBody;
