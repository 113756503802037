import { classNames } from 'primereact/utils';
import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useInplaceEdit } from '../../contexts/product-details-page-inplace-edit/ProductDetailsPageInplaceEditContext';
import { useGetProductDetails } from '../../hooks/api/products';
import ProductBreadCrumb from './components/product-breadcrumb/ProductBreadcrumb';
import CarbonFootprintDetailsSection from './components/sections/carbon-footprint-details-section/CarbonFootprintDetails';
import GeneralDetailsSection from './components/sections/general-details-section/GeneralDetailsSection';
import ProductCarbonFootprintSection from './components/sections/product-carbon-footprint-section/ProductCarbonFootprintSection';
import ProductNameSection from './components/sections/product-name-section/ProductNameSection';

const ProductDetailsPage = (): JSX.Element => {
  // Get productId from URL
  const { productId } = useParams() as { productId: string };

  // Descturture the inplace edit context
  const { setProductId: setContextProductId } = useInplaceEdit();

  // Fetch JSON data from Pacific API
  const {
    isLoading,
    isError,
    data: productDetails,
  } = useGetProductDetails(productId, { retry: 1 });

  // Set the productId in the inplace edit context
  useEffect(() => {
    setContextProductId(productId);
  }, [productId, setContextProductId]);

  if (isError || (!isLoading && !(productDetails || Object.keys(productDetails).length === 0))) {
    return <Navigate to='/404' replace={true} />;
  }

  return (
    <div className={classNames('p-5 flex flex-column gap-3')}>
      {/* Breadcrumb */}
      <ProductBreadCrumb isLoading={isLoading} productName={productDetails?.productName} />

      {/* Product Name Section*/}
      <ProductNameSection
        isLoading={isLoading}
        mainProductName={productDetails?.productName}
        ownProductName={productDetails?.own_product_name}
        otherRequestData={productDetails?.other_request_data}
      />

      {/* Top Section */}
      <div className='grid m-0 gap-3'>
        <GeneralDetailsSection
          productDetails={productDetails}
          isLoading={isLoading}
          className='col-12 lg:col'
        />
        <ProductCarbonFootprintSection
          productDetails={productDetails}
          isLoading={isLoading}
          className='col-12 lg:col'
        />
      </div>

      {/* Bottom Section */}
      <div className='grid m-0 '>
        <CarbonFootprintDetailsSection
          productDetails={productDetails}
          isLoading={isLoading}
          className='col'
        />
      </div>
    </div>
  );
};

export default ProductDetailsPage;
