import { TFunction } from 'i18next';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomMultiSelect from '../../../components/custom-multi-select/CustomMultiSelect';
import SearchBar from '../../../components/search-bar/SearchBar';
import { PCFBiogenicEmissionsMode } from '../../../shared/enums/pcf';
import { PCFRequestRegionMap, PCFRequestStatusMap } from '../../../shared/enums/pcf-request';
import { IDropdownItem } from '../../../shared/interfaces/IDropdownItem';
import ExportPcfsModal from '../../modals/export-pcfs-modal/ExportPcfsModal';
import { ProductFilters } from '../interfaces/product-item';
import ExportOrRequestPcfsButtons from './ExportOrRequestPcfsButtons';

type ProductsHeaderProps = {
  filterChange: (filters: ProductFilters) => void;
  biogenicEmissionsMode: PCFBiogenicEmissionsMode;
  setBiogenicEmissionsMode: (mode: PCFBiogenicEmissionsMode) => void;
};

const getTranslatedEmissionsRemovals = (t: TFunction<'translation', undefined>) => {
  const options = Object.values(PCFBiogenicEmissionsMode);
  return options.map((mode) => {
    return {
      label: t(`biogenicEmissionsMode.${mode.toLowerCase()}`),
      value: mode,
    };
  });
};

export default function ProductsHeader({
  filterChange,
  biogenicEmissionsMode,
  setBiogenicEmissionsMode,
}: ProductsHeaderProps) {
  const [productFilters, setProductFilters] = useState<ProductFilters>({
    statusFilter: [],
    regionsFilter: [],
    searchStr: '',
  });

  const [showExportPcfsModal, setShowExportPcfsModal] = useState(false);

  const { t } = useTranslation();

  const translatedStatuses = useMemo(() => {
    return Object.entries(PCFRequestStatusMap).map(([code]) => {
      return {
        name: t(`pcfRequest.${code}`),
        code,
      };
    });
  }, [PCFRequestStatusMap]);

  const translatedRegion = useMemo(() => {
    return Object.entries(PCFRequestRegionMap).map(([code]) => {
      return {
        name: t(`pcfRequest.${code}`),
        code,
      };
    });
  }, [PCFRequestRegionMap]);

  useEffect(() => {
    filterChange(productFilters);
  }, [productFilters]);

  const onSearchChange = (value: string) => {
    setProductFilters((prevProuctFilters) => {
      return {
        ...prevProuctFilters,
        searchStr: value,
      };
    });
  };

  const setFilters = (selectedItems: IDropdownItem[], key: 'statusFilter' | 'regionsFilter') => {
    const codes = selectedItems.map((item) => {
      return item.code;
    });

    setProductFilters((prevProuctFilters) => {
      return {
        ...prevProuctFilters,
        [key]: codes,
      };
    });
  };

  return (
    <div className='flex flex-column'>
      <ExportOrRequestPcfsButtons setShowExportPcfsModal={setShowExportPcfsModal} />

      <div className='flex flex-column md:flex-row md:justify-content-between md:gap-4'>
        <div className='flex justify-content-between align-items-center gap-2 mb-4 md:mb-0 w-full'>
          <span>{t('productsPage.biogenicEmissionsRemovals')}</span>
          <SelectButton
            className='flex-grow-1 flex'
            value={biogenicEmissionsMode}
            onChange={(e: SelectButtonChangeEvent) => {
              setBiogenicEmissionsMode(e.value);
            }}
            options={getTranslatedEmissionsRemovals(t)}
            unselectable={false}
          />
        </div>

        <div className={classNames('flex flex-column xl:flex-row gap-2 xl:gap-4')}>
          <div className='flex align-items-center justify-content-between xl:gap-2'>
            <label>{t('productsPage.filters')} </label>
            <div className='flex gap-2'>
              <CustomMultiSelect
                options={translatedStatuses}
                label={t('productsPage.status')}
                maxSelectedOptions={2}
                onApplyFilter={(selectedItems: IDropdownItem[]) => {
                  setFilters(selectedItems, 'statusFilter');
                }}
                onClearFilter={() => {
                  setFilters([], 'statusFilter');
                }}
              />
              <CustomMultiSelect
                options={[{ code: 'G', name: t('pcfRequest.global') }, ...translatedRegion]}
                label={t('productsPage.regions')}
                maxSelectedOptions={2}
                onApplyFilter={(selectedItems: IDropdownItem[]) => {
                  setFilters(selectedItems, 'regionsFilter');
                }}
                onClearFilter={() => {
                  setFilters([], 'regionsFilter');
                }}
              />
            </div>
          </div>
          <SearchBar onSearchChange={onSearchChange} />
        </div>
      </div>

      <ExportPcfsModal
        visible={showExportPcfsModal}
        onHide={() => {
          return setShowExportPcfsModal(false);
        }}
        biogenicEmissionsMode={biogenicEmissionsMode}
      />
    </div>
  );
}
