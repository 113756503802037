import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { ExportPcfsResponse } from '../../../../hooks/api/products';

type SuccessToastContentProps = {
  response: ExportPcfsResponse;
  clear: () => void;
};

const SuccessToastContent = ({ response, clear }: SuccessToastContentProps): JSX.Element => {
  const { t } = useTranslation();

  const { fileUrl, errorCount } = response;

  let title, detail;

  if (errorCount) {
    title = t('toastMessages.exportPcfs.states.successWithErrors.title');
    detail = t('toastMessages.exportPcfs.states.successWithErrors.detail', { errorCount });
  } else {
    title = t('toastMessages.exportPcfs.states.successWithoutErrors.title');
    detail = t('toastMessages.exportPcfs.states.successWithoutErrors.detail');
  }

  return (
    <>
      <i className='pi pi-check p-notification-custom-icon success-background text-white' />
      <div className='p-toast-message-text'>
        {/* Title */}
        <span className='p-toast-summary'>{title}</span>

        {/* Detail */}
        <div className='p-toast-detail'>{detail}</div>

        {/* Button */}
        <div className='mt-2'>
          <Button
            className='p-button-sm'
            label={t('toastMessages.exportPcfs.states.successWithoutErrors.downloadButtonLabel')}
            severity='success'
            onClick={() => {
              // Download the exported CSV file when clicked
              // Create a temporary link element
              const link = document.createElement('a');
              link.href = fileUrl;

              // Append the link to the document body (this step is necessary for some browsers)
              document.body.appendChild(link);

              // Programmatically trigger a click event on the link
              link.click();

              // Clean up by removing the link element
              document.body.removeChild(link);

              // Close all the toasts
              clear();
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SuccessToastContent;
