/* eslint-disable camelcase */

import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { Dispatch, SetStateAction } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import TextAreaInput from '../../../components/forms/text-area-input/TextAreaInput';
import TextInput from '../../../components/forms/text-input/TextInput';
import { useCreateAdditionalProductRequest } from '../../../hooks/api/request-additional-product';
import useNotificationContext from '../../../hooks/notification/useNotificationContext';
import { ToastSeverity } from '../../../shared/enums/toast-severity';
import CancelButton from './components/cancel-button/CancelButton';
import styles from './RequestAdditionalProductModal.module.css';

type RequestAdditionalProductModalProps = {
  visible?: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
};

type FormInput = {
  productName: string;
  productId: string;
  comment?: string;
};

const RequestAdditionalProductModal = ({
  visible = false,
  setVisible,
}: RequestAdditionalProductModalProps) => {
  const { notify } = useNotificationContext();

  const { t } = useTranslation();

  const mySchema = yup.object().shape({
    productName: yup
      .string()
      .required(t('additionalProductRequest.validationErrors.productNameRequiredError'))
      .trim()
      .min(3, t('additionalProductRequest.validationErrors.productNameMinCharacterLimitError'))
      .test(
        'not-all-characters-same',
        t('additionalProductRequest.validationErrors.productNameSameCharactersError'),
        (value: string) => {
          // Check if all the characters are not the same
          return new Set(value.split('')).size > 1;
        },
      ),
    productId: yup
      .string()
      .required(t('additionalProductRequest.validationErrors.productIdRequiredError'))
      .trim()
      .min(3, t('additionalProductRequest.validationErrors.productIdMinCharacterLimitError')),
    comment: yup
      .string()
      .max(1000, t('additionalProductRequest.validationErrors.commentMaxCharacterLimitError'))
      .optional(),
  });

  const {
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    defaultValues: { productName: '', productId: '', comment: '' },
    resolver: yupResolver(mySchema),
  });

  const onSubmitSuccess = (): void => {
    notify({
      severity: ToastSeverity.INFO,
      summary: t('additionalProductRequest.requestSubmitted'),
      detail: (
        <>
          <p className='m-0 mt-2'>
            {t('additionalProductRequest.yourRequestWillBeReviewedAsSoonAsPossible')}
          </p>{' '}
          <p className='m-0'>
            {t('additionalProductRequest.checkBackPeriodicallyToSeeIfYourProductIsAdded')}
          </p>
        </>
      ),
      life: 5000,
    });
    setVisible(false);
    resetForm();
  };

  const onSubmitError = (): void => {
    notify({
      severity: ToastSeverity.ERROR,
      summary: t('additionalProductRequest.failedToSubmitRequest'),
      detail: (
        <>
          <p className='m-0 mt-2'>{t('additionalProductRequest.somethingWentWrong')}</p>
        </>
      ),
      life: 5000,
    });
  };

  const { isLoading: isCreatingAdditionalProductRequest, mutate: createAdditionalProductRequest } =
    useCreateAdditionalProductRequest({
      onSuccess: onSubmitSuccess,
      onError: onSubmitError,
    });

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const productName = data.productName;
    const productId = data.productId;
    const comment = data.comment;

    createAdditionalProductRequest({
      product_name: productName,
      curt_product_id: productId,
      request_description: comment,
    });
  };

  const footerContent = (
    <div className='flex flex-row gap-2 justify-content-end w-full'>
      <CancelButton setVisible={setVisible} isCreatingAdditionalProductRequest={false} />

      <Button
        label={t('additionalProductRequest.requestProduct')}
        onClick={handleSubmit(onSubmit)}
        loading={isCreatingAdditionalProductRequest}
        disabled={isCreatingAdditionalProductRequest}
      />
    </div>
  );

  const onModalHide = () => {
    resetForm();
    setVisible(false);
  };

  return (
    <Dialog
      header={t('additionalProductRequest.heading')}
      visible={visible}
      draggable={false}
      blockScroll
      footer={footerContent}
      onHide={onModalHide}
      className={classNames('bg-white', styles['additional-product-modal'])}
    >
      <p className='mt-2 mb-4'>{t('additionalProductRequest.label')}</p>

      <div className='my-4'>
        <TextInput
          name='productName'
          type='text'
          label={t('additionalProductRequest.productName')}
          control={control}
        />
      </div>
      <div className='my-4'>
        <TextInput
          name='productId'
          type='text'
          label={t('additionalProductRequest.productId')}
          control={control}
        />
      </div>
      <div className={classNames('my-4')}>
        <TextAreaInput
          name='comment'
          label={t('additionalProductRequest.comment')}
          control={control}
          rows={6}
          className='w-full h-full'
        />
      </div>
    </Dialog>
  );
};

export default RequestAdditionalProductModal;
/* eslint-enable camelcase */
