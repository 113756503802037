import { IProduct } from '../../../shared/interfaces/IProduct';
import ProductId from './ProductId';

const ProductIdColBody = (product: IProduct) => {
  return (
    <ProductId
      productId={product.product_cid}
      ownProductId={product.own_product_id}
      otherRequestData={product.other_request_data}
    />
  );
};

export default ProductIdColBody;
