import { InputText, InputTextProps } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import {
  FieldValues,
  useController,
  UseControllerProps,
  UseFormClearErrors,
} from 'react-hook-form';

import ErrorMessage from '../../error-message';
import styles from './TextInput.module.css';

export type TextInputProps<T extends FieldValues> = Omit<InputTextProps, 'name'> & {
  label?: string;
  clearErrors?: UseFormClearErrors<T>;
  showError?: boolean;
} & UseControllerProps<T>;

const TextInput = <T extends FieldValues>({
  name,
  control,
  rules,
  defaultValue,
  label,
  className,
  clearErrors,
  showError = true,
  ...props
}: TextInputProps<T>) => {
  const {
    field: { ref, onChange, ...inputProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <div className='field p-fluid'>
      <span className='p-float-label'>
        <InputText
          id={name}
          ref={ref}
          onChange={(e) => {
            onChange(e);
            clearErrors?.(name);
          }}
          {...props}
          {...inputProps}
          className={classNames(className, {
            'p-invalid': error,
          })}
        />
        {label && <label htmlFor={name}>{label}</label>}
      </span>
      {showError && (
        <div className={classNames(styles['error-message-box'])}>
          {invalid && <ErrorMessage message={error?.message || ''} />}
        </div>
      )}
    </div>
  );
};

export default TextInput;
