import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { useUpdateProductOwnData } from '../../hooks/api/products';
import { InplaceEditContext } from './ProductDetailsPageInplaceEditContext';

type ProductDetailsPageInplaceEditProviderProps = {
  children: React.ReactNode;
};

export const ProductDetailsPageInplaceEditProvider = ({
  children,
}: ProductDetailsPageInplaceEditProviderProps) => {
  const [productId, setProductId] = useState<string | null>(null);

  const [activeFieldId, setActiveFieldId] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const { mutate: updateProductOwnData, isLoading: isSaving } = useUpdateProductOwnData({
    onSuccess: () => {
      // When the mutation succeeds, invalidate the query to refetch the product details
      queryClient.invalidateQueries({ queryKey: ['getProductDetails', productId] });
    },
    onError: () => {
      // When the mutation fails, emit an event to the InplaceEdit component to rollback to the previous value
      window.dispatchEvent(new Event(`inplace-edit:${activeFieldId}:rollback`));
    },
    onSettled: () => {
      deactivate();
    },
  });

  const activate = (fieldId: string) => {
    // Prevent activation of the same field
    if (activeFieldId === fieldId) {
      return;
    }

    // Prevent activation when saving another field
    if (isSaving) {
      return;
    }

    setActiveFieldId(fieldId);
  };

  const deactivate = () => {
    setActiveFieldId(null);
  };

  const handleSave = async <T,>(productId: string, fieldId: string, newValue: T) => {
    updateProductOwnData({
      productId,
      [fieldId]: newValue,
    });
  };

  return (
    <InplaceEditContext.Provider
      value={{
        productId,
        setProductId,
        activeFieldId,
        activate,
        deactivate,
        handleSave,
        isSaving,
      }}
    >
      {children}
    </InplaceEditContext.Provider>
  );
};

export default ProductDetailsPageInplaceEditProvider;
