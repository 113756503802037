import { Skeleton } from 'primereact/skeleton';
import { classNames } from 'primereact/utils';

type InfoCardRowProps = {
  label: string;
  textColor?: string;
  isParentWide?: boolean;
  children: React.ReactNode;
  isLoading?: boolean;
};

const InfoCardRow = ({
  label,
  textColor,
  isParentWide,
  children,
  isLoading,
}: InfoCardRowProps): JSX.Element => {
  return (
    <div
      className={classNames(
        'w-full flex justify-content-between lg:flex-none lg:grid align-items-center',
      )}
    >
      {/* Label */}
      <div
        className={classNames(
          'text-gray-500 font-bold ',
          textColor,
          isParentWide ? 'col-4' : 'lg:col-6',
        )}
      >
        {label}
      </div>

      {/* Children */}
      {isLoading ? (
        <div className='w-full h-full flex ml-2 py-1 lg:py-2'>
          <Skeleton height='auto' />
        </div>
      ) : (
        <div className={classNames(textColor, isParentWide ? 'col-8' : 'lg:col-6')}>{children}</div>
      )}
    </div>
  );
};

export default InfoCardRow;
