/* eslint-disable camelcase */
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';

import AmplifyApiService from '../../services/AmplifyApiService';
import IAmplifyApiConfig from '../../shared/interfaces/IAmplifyApiConfig';
import { INotification } from '../../shared/interfaces/INotification';
import { IPagination } from '../../shared/interfaces/IPagination';

const amplifyApiService = new AmplifyApiService();

type NotificationsResponse = { notifications: INotification[]; pagination: IPagination };

export type NotificationsParams = {
  read?: boolean;
  size?: number;
  skip?: number;
};

export const useListNotifications = (
  params: NotificationsParams = {},
): UseQueryResult<NotificationsResponse> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'notification',
    init: {
      queryStringParameters: params,
    },
  };

  const query = useQuery<NotificationsResponse, Error>({
    queryKey: ['notifications', params],
    queryFn: async (): Promise<NotificationsResponse> => {
      const response = await amplifyApiService.get<NotificationsResponse>({
        config: amplifyApiConfig,
      });

      return response;
    },
    cacheTime: 0, // Doesn't cache any data
  });

  return query;
};

export const useUpdateNotification = (): UseMutationResult<void, Error, number> => {
  const amplifyApiConfig: IAmplifyApiConfig = {
    apiName: 'PacificApi',
    path: 'notification',
    init: {},
  };

  const mutation = useMutation<void, Error, number>({
    mutationFn: async (id: number) => {
      await amplifyApiService.put<
        { read: boolean },
        {
          message: string;
          data: boolean;
        }
      >({
        config: { ...amplifyApiConfig, path: `${amplifyApiConfig.path}/${id}` },
        body: { read: true },
      });
    },
  });

  return mutation;
};
