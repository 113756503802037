import { ToastMessage } from 'primereact/toast';

type ToastMessageSeverityType = NonNullable<ToastMessage['severity']>;

export const NOTIFICATION_ICONS: Partial<Record<ToastMessageSeverityType, string>> = {
  success: 'check',
  info: 'info',
  error: 'times',
  warn: 'exclamation-triangle',
};
