import { classNames } from 'primereact/utils';
import { Children, cloneElement, ComponentProps, isValidElement } from 'react';

import useWidth from '../../../../hooks/layout/useWidth';

interface ChildrenProps {
  isParentWide: boolean;
  isLoading: boolean;
}

interface InfoCardProps extends ComponentProps<'div'> {
  isLoading?: boolean;
  children: React.ReactNode;
}

const InfoCard = (props: InfoCardProps): JSX.Element => {
  const { isLoading, children, className, ...rest } = props;

  // Get the width of the container via useWidth hook
  // So that we can arrange grid layout based on the width
  const [ref, width] = useWidth();

  // Check if the width is greater than 1300px
  const isParentWide = width > 1300;

  // If the component is wider than 1300px, pass the isParentWide prop to the children
  // So that they will arrange their grid layout accordingly
  // Also attach isLoading prop to the children, so that they can show Skeleton or any other loading state
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement<ChildrenProps>(child) && typeof child.type !== 'string') {
      return cloneElement<ChildrenProps>(child, { isParentWide, isLoading });
    }
    return child;
  });

  return (
    <div
      ref={ref}
      {...rest}
      className={classNames(
        'bg-white px-3 py-4 border-round-2xl flex flex-column gap-3',
        className,
      )}
    >
      {childrenWithProps}
    </div>
  );
};

export default InfoCard;
