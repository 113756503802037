import { createContext } from 'react';

import { INotificationContext } from '../../shared/interfaces/INotificationContext';

const defaultState: INotificationContext = {
  notify: (): void => {
    throw new Error('notify() must be implemented');
  },
  remove: (): void => {
    throw new Error('remove() must be implemented');
  },
  clear: (): void => {
    throw new Error('clear() must be implemented');
  },
  toastRef: { current: null },
  isNewNotification: false,
  setIsNewNotification: (): void => {
    throw new Error('setIsNewNotification() must be implemented');
  },
};

const NotificationContext = createContext(defaultState);

export default NotificationContext;
