import { useTranslation } from 'react-i18next';

import { mapProductRegionToKey } from '../../../shared/enums/product-region';
import { IProduct } from '../../../shared/interfaces/IProduct';

const ProductRegionColBody = (product: IProduct) => {
  const { t } = useTranslation();

  if (!product.region) return;

  return t(`productsPage.regionNames.${mapProductRegionToKey(product.region)}`);
};

export default ProductRegionColBody;
