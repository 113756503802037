import { t } from 'i18next';

export const timeAgo = (value: string): string => {
  const d = new Date(value);
  const now = new Date();
  const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
  const minutes = Math.round(Math.abs(seconds / 60));
  const hours = Math.round(Math.abs(minutes / 60));
  const days = Math.round(Math.abs(hours / 24));
  const months = Math.round(Math.abs(days / 30.416));
  const years = Math.round(Math.abs(days / 365));

  if (Number.isNaN(seconds)) {
    return '';
  } else if (seconds <= 45) {
    return t('notificationsDialog.aFewSecondsAgo');
  } else if (seconds <= 90) {
    return t('notificationsDialog.aMinuteAgo');
  } else if (minutes <= 45) {
    return `${minutes} ${t('notificationsDialog.minutesAgo')}`;
  } else if (minutes <= 90) {
    return t('notificationsDialog.anHourAgo');
  } else if (hours <= 22) {
    return `${hours} ${t('notificationsDialog.hoursAgo')}`;
  } else if (hours <= 36) {
    return t('notificationsDialog.aDayAgo');
  } else if (days <= 25) {
    return `${days} ${t('notificationsDialog.daysAgo')}`;
  } else if (days <= 45) {
    return t('notificationsDialog.aMonthAgo');
  } else if (days <= 345) {
    return `${months} ${t('notificationsDialog.monthsAgo')}`;
  } else if (days <= 545) {
    return t('notificationsDialog.aYearAgo');
  } else {
    return `${years} ${t('notificationsDialog.yearsAgo')}`;
  }
};
