import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

import FileJSONIcon from '../../assets/icons/FileJSON';
import FileJSONGrayIcon from '../../assets/icons/FileJSONGray';
import { useGetRequestJsonDownloadUrl } from '../../hooks/api/request-pcf';
import { useAuth } from '../../hooks/auth/useAuth';

export type DownloadJsonFileButtonProps = {
  className?: string;
  productName: string;
  requestId: number;
  showDownloadText?: boolean;
};

const DownloadJsonFileButton = ({
  className,
  productName,
  requestId,
  showDownloadText = false,
}: DownloadJsonFileButtonProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const isDisabled = user?.company?.license === 'basic';

  const { refetch: fetchJsonDownload } = useGetRequestJsonDownloadUrl(requestId);

  const handleClick = async () => {
    const { data, isSuccess } = await fetchJsonDownload();

    if (isSuccess && data) {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
      const link = document.createElement('a');
      link.href = jsonString;
      link.download = `${productName.toLowerCase().replace(' ', '_')}_pcf.json`;

      link.click();

      queryClient.invalidateQueries({ queryKey: ['listProducts'] });
    }
  };

  const handleEmailClick = () => {
    window.open(
      'mailto:Pacific.Support@basf.com?subject=Upgrade%20inquiry&body=Dear%20PACIFIC%20Team,%0D%0A%0D%0AI%20am%20reaching%20out%20to%20request%20information%20about%20paid%20upgrades%20for%20PACIFIC.%20Please%20share%20more%20detailed%20information%20and%20get%20in%20touch%20with%20me.%0D%0A%0D%0AThank%20you.%0D%0A%0D%0ABest%20regards,%0D%0A%5BFull%20name%5D%0D%0A%5BEmail%20address%5D',
    );
  };

  return (
    <div>
      {isDisabled && (
        <Tooltip
          target={`.json-download-btn-${requestId}`}
          position='top'
          autoHide={false}
          baseZIndex={1}
          pt={{ root: { className: 'pointer-events-auto' } }}
        >
          <div>
            <span>{t('productsPage.jsonDownloadDisabledTooltip.part1')}</span>{' '}
            <Button
              link
              label={t('productsPage.jsonDownloadDisabledTooltip.clickHere')}
              className='p-0'
              onClick={handleEmailClick}
            />{' '}
            <span>{t('productsPage.jsonDownloadDisabledTooltip.part2')}</span>
          </div>
        </Tooltip>
      )}
      <Button
        className={classNames(
          `p-0 json-download-btn-${requestId}`,
          {
            'cursor-pointer': !isDisabled,
            'cursor-auto': isDisabled,
          },
          className,
        )}
        icon={isDisabled ? FileJSONGrayIcon : FileJSONIcon}
        link
        onClick={handleClick}
      />
      {showDownloadText ? (
        <Button className='text-primary underline' link onClick={handleClick}>
          {t('downloadPcfDialog.downloadJSONFile')}
        </Button>
      ) : null}
    </div>
  );
};

export default DownloadJsonFileButton;
