import { Button, ButtonProps } from 'primereact/button';
import { classNames, IconOptions } from 'primereact/utils';
import { useMatch, useNavigate } from 'react-router-dom';

import styles from './MobileHeaderLink.module.css';

type MobileHeaderLinkProps = {
  href: string;
  label: string;
  icon?: (options: IconOptions<ButtonProps, unknown>) => JSX.Element;
  activeIcon?: (options: IconOptions<ButtonProps, unknown>) => JSX.Element;
};

const MobileHeaderLink = ({ href, label, icon, activeIcon }: MobileHeaderLinkProps) => {
  const match = useMatch(href);
  const navigate = useNavigate();

  const onClick = () => {
    navigate(href);
  };

  return (
    <Button
      className={classNames('border-noround h-full', styles['mobile-header-link'])}
      pt={{
        label: {
          className: classNames('text-lg', styles['dark-label'], {
            'font-bold': match,
          }),
        },
        icon: {
          className: classNames({
            'text-primary': match,
            [styles['inactive-icon'] as string]: !match,
          }),
        },
      }}
      onClick={onClick}
      icon={match ? activeIcon : icon}
      label={label}
      text
    />
  );
};

export default MobileHeaderLink;
